
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import ZHourTab from "@/components/stations/ZHourTab.vue";
import WorkingHoursTab from "@/components/stations/WorkingHoursTab.vue";
import CooksTab from "@/components/stations/CooksTab.vue";
import BsoTab from "@/components/stations/BsoTab.vue";
import BsoIngredientTab from "@/components/stations/BsoIngredientTab.vue";
import EquipmentTab from "@/components/stations/EquipmentTab.vue";
import OtherSettingsTab from "@/components/stations/OtherSettingsTab.vue";

import { IStation } from "@common/station";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

export default Vue.extend({
	name: "HAACP",
	components: {
		"z-hour-tab": ZHourTab,
		"working-hours-tab": WorkingHoursTab,
		"cooks-tab": CooksTab,
		"bso-tab": BsoTab,
		"bso-ingredient-tab": BsoIngredientTab,
		"equipment-tab": EquipmentTab,
		"other-settings-tab": OtherSettingsTab,
	},
	data: () => {
		return {
			gasStation: {} as IStation,
			gasStations: [] as IStation[],
			tab: 0,
			get role (): string {
				return localStorage.getItem("role") || "";
			}
		};
	},
	created: async function () {
		this.store.dispatch.changeAppTitle("Stations");
		try {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${store.getters.serverURL}/station/all`,
			};
			const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				this.gasStations = res.data.payload.map((item) => {
					item.name = `(${item.id}) ${item.name}`;

					return item;
				});
				if (this.gasStations.length) {
					this.gasStation = this.gasStations[0];
				}
			}
		} catch (err) {
			console.error(err);
		}
	},
	computed: {
		filteredTabs () {
			const tabs = [
				{ key: "zHourTab", label: "Z Hour", component: "z-hour-tab", condition: this.role === "admin" || this.role === "sys-admin" },
				{ key: "workingHoursTab", label: "Working Hours", component: "working-hours-tab", condition: true },
				{ key: "cooksTab", label: "Cooks", component: "cooks-tab", condition: true },
				{ key: "bsoTab", label: "BSO Products", component: "bso-tab", condition: true },
				{ key: "bsoIngredientTab", label: "BSO Ingredients", component: "bso-ingredient-tab", condition: true },
				{ key: "equipmentTab", label: "Equipment", component: "equipment-tab", condition: true },
				{ key: "otherSettings", label: "Other Settings", component: "other-settings-tab", condition: true },
			];
			return tabs.filter(tab => tab.condition);
		},
	}
});
