import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"fluid":""}},[(!_vm.loading)?[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,[_c(VContainer,[_c(VRow,{staticClass:"mt-2"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Countdown Timer Minutes","rules":_vm.notificationTimerMinutesRules},model:{value:(_vm.timerSettings.countdownTimerMinutes),callback:function ($$v) {_vm.$set(_vm.timerSettings, "countdownTimerMinutes", $$v)},expression:"timerSettings.countdownTimerMinutes"}})],1)],1),_c(VRow,{staticClass:"mt-2"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"New Commands Minutes","rules":_vm.newCommandsMinutesRules},model:{value:(_vm.timerSettings.newCommandsMinutes),callback:function ($$v) {_vm.$set(_vm.timerSettings, "newCommandsMinutes", $$v)},expression:"timerSettings.newCommandsMinutes"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.save}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("save")]),_vm._v("Save ")],1)],1)],1),_c(VSnackbar,{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("clear")])],1)]}}],null,false,3501873549),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])]:[_c(VSkeletonLoader,{attrs:{"type":"\n\t\t\t\t\t\tparagraph,divider,list-item,divider,\n\t\t\t\t\t\tlist-item,divider,list-item,divider,\n\t\t\t\t\t\tlist-item,divider,list-item,divider,\n\t\t\t\t\t\tlist-item,divider,list-item,divider,\n\t\t\t\t\t\tlist-item"}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }