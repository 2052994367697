
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { ITimerSettings } from "@common/timerSettings";

export default Vue.extend({
	name: "OtherSettingsTab",
	data () {
		const timerSettings: ITimerSettings = {
			countdownTimerMinutes: 0,
			newCommandsMinutes: 0,
		};

		const snackbar = {
			show: false,
			text: "",
			color: "primary",
		};

		return {
			loading: false,
			timerSettings,
			snackbar,
			notificationTimerMinutesRules: [
				(v: string) => !!v || "Countdown Timer Minutes is required",
				(v: string) => parseInt(v) >= 0 || "Countdown Timer Minutes must be a positive number",
			],
			newCommandsMinutesRules: [
				(v: string) => !!v || "New Commands Minutes is required",
				(v: string) => parseInt(v) >= 0 || "New Commands Minutes must be a positive number",
			]
		};
	},
	created () {
		this.fetchData();
	},
	methods: {
		async fetchData () {
			this.loading = true;

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/timer-settings`,
				};

				const res: AxiosResponse<IServerRES<ITimerSettings>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.timerSettings = res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
		async save () {
			this.loading = true;

			try {
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/timer-settings`,
					data: this.timerSettings,
				};

				const res: AxiosResponse<IServerRES<boolean>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.snackbar = {
						show: true,
						text: "Other settings saved successfully!",
						color: "success",
					};
				} else {
					this.snackbar = {
						show: true,
						text: "Failed to save other settings!",
						color: "error",
					};
				}
			} catch (err) {
				console.error(err);

				this.snackbar = {
					show: true,
					text: "Failed to save other settings!",
					color: "error",
				};
			}

			setTimeout(() => {
				this.loading = false;
			}, 300);
		}
	}
});
