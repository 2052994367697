import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VCard,[_c(VCardTitle,[_c(VCol,{attrs:{"cols":"4"}},[_c(VAutocomplete,{attrs:{"label":"Gas Station","items":_vm.gasStations,"item-text":"name","return-object":""},model:{value:(_vm.gasStation),callback:function ($$v) {_vm.gasStation=$$v},expression:"gasStation"}})],1)],1),_c(VCardText,[_c(VTabs,{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.filteredTabs),function(tabItem){return _c(VTab,{key:tabItem.key},[_vm._v(" "+_vm._s(tabItem.label)+" ")])}),1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.filteredTabs),function(tabItem,index){return _c(VTabItem,{key:tabItem.key},[(_vm.tab === index)?_c(tabItem.component,{tag:"component",attrs:{"stationId":_vm.gasStation.id}}):_vm._e()],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }