
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { DEFAULT_ENDING_HOUR, DEFAULT_INVENTORY_HOUR, DEFAULT_INVENTORY_MINUTE, DEFAULT_MULTIPLICATION_INDEX, DEFAULT_STARTING_HOUR, IStationWorkingHours } from "@common/station";
import _ from "lodash";

export default Vue.extend({
	name: "WorkingHoursTab",
	props: {
		stationId: String,
	},
	data () {
		const hours: {
			day: string;
			startingHour: number;
			endingHour: number;
			inventoryHour: {
				hour: number;
				minute: number;
			};
			multiplicationIndex: number;
		}[] = [];

		const snackbar = {
			show: false,
			text: "",
			color: "primary",
		};

		const allHours = Array.from({ length: 24 }, (_, i) => i);

		return {
			hours,
			snackbar,
			allAvailableStartHours: Array(7).fill(_.cloneDeep(allHours)),
			allAvailableEndHours: Array(7).fill(_.cloneDeep(allHours)),
			allAvailableHours: Array(7).fill(_.cloneDeep(allHours)),
			loading: false,
			multiplicationIndexRules: [
				(value: string) => !!value || "Required.",
				(value: string) => (parseInt(value) >= 0 && parseInt(value) <= 500) || "Sales percentage must be between 0 and 500"
			],
			inventoryHourRules: [
				(value: string) => !!value || "Required.",
				(value: string) => (parseInt(value) >= 0 && parseInt(value) <= 23) || "Hour must be between 0 and 23"
			],
			inventoryMinuteRules: [
				(value: string) => !!value || "Required.",
				(value: string) => (parseInt(value) >= 0 && parseInt(value) <= 59) || "Minute must be between 0 and 59"
			],
		};
	},
	created () {
		this.fetchData();
	},
	methods: {
		multiplicationIndexCompute (integer: number, decimal: number): number {
			if (decimal < 10) {
				decimal = decimal * 10;
			}

			const result: number = Number(integer) + Number(decimal) / 100;
			return result;
		},
		async fetchData () {
			this.loading = true;

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/working-hours/${this.stationId}`,
				};

				const res: AxiosResponse<IServerRES<IStationWorkingHours[]>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.hours = res.data.payload.map((item: IStationWorkingHours) => {
						return {
							day: this.numberToDay(item.day),
							startingHour: item.startingHour,
							endingHour: item.endingHour,
							inventoryHour: {
								hour: item.inventoryHour,
								minute: item.inventoryMinute,
							},
							multiplicationIndex: item.multiplicationIndex * 100,
						};
					});
				}
			} catch (err) {
				console.error(err);
			}

			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
		async save () {
			this.loading = true;

			const data: IStationWorkingHours[] = this.hours.map((item) => {
				return {
					stationId: this.stationId,
					day: this.dayToNumber(item.day),
					startingHour: item.startingHour,
					endingHour: item.endingHour,
					inventoryHour: item.inventoryHour.hour,
					inventoryMinute: item.inventoryHour.minute,
					multiplicationIndex: Number((item.multiplicationIndex / 100).toFixed(2)),
				};
			});

			try {
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/working-hours`,
					data
				};

				const res: AxiosResponse<IServerRES<boolean>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.snackbar = {
						show: true,
						text: "Working hours saved successfully!",
						color: "success",
					};
				} else {
					this.snackbar = {
						show: true,
						text: "Failed to save working hours!",
						color: "error",
					};
				}
			} catch (err) {
				console.error(err);

				this.snackbar = {
					show: true,
					text: "Failed to save working hours!",
					color: "error",
				};
			}

			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
		dayToNumber (day: string) {
			switch (day) {
				case "Monday":
					return 0;
				case "Tuesday":
					return 1;
				case "Wednesday":
					return 2;
				case "Thursday":
					return 3;
				case "Friday":
					return 4;
				case "Saturday":
					return 5;
				case "Sunday":
					return 6;
				default:
					return 0;
			}
		},

		numberToDay (day: number) {
			switch (day) {
				case 0:
					return "Monday";
				case 1:
					return "Tuesday";
				case 2:
					return "Wednesday";
				case 3:
					return "Thursday";
				case 4:
					return "Friday";
				case 5:
					return "Saturday";
				case 6:
					return "Sunday";
				default:
					return "Monday";
			}
		}
	},
	computed: {
		headers () {
			return [
				{ text: "Day", value: "day", align: "start", minWidth: "100" },
				{ text: "Start Hour", value: "startingHour", align: "start", minWidth: "100" },
				{ text: "End Hour", value: "endingHour", align: "start", minWidth: "100" },
				{ text: "Inventory Hour", value: "inventoryHour", align: "start", minWidth: "100" },
				{ text: "Sales percentage", value: "multiplicationIndex", align: "start", minWidth: "100" },
			];
		}
	},
	watch: {
		stationId: function () {
			this.fetchData();
		},
		hours: {
			handler: function () {
				if (this.hours.length !== 7) {
					this.hours = [
						{
							day: "Monday",
							startingHour: DEFAULT_STARTING_HOUR,
							endingHour: DEFAULT_ENDING_HOUR,
							inventoryHour: {
								hour: DEFAULT_INVENTORY_HOUR,
								minute: DEFAULT_INVENTORY_MINUTE
							},
							multiplicationIndex: DEFAULT_MULTIPLICATION_INDEX
						},
						{
							day: "Tuesday",
							startingHour: DEFAULT_STARTING_HOUR,
							endingHour: DEFAULT_ENDING_HOUR,
							inventoryHour: {
								hour: DEFAULT_INVENTORY_HOUR,
								minute: DEFAULT_INVENTORY_MINUTE
							},
							multiplicationIndex: DEFAULT_MULTIPLICATION_INDEX
						},
						{
							day: "Wednesday",
							startingHour: DEFAULT_STARTING_HOUR,
							endingHour: DEFAULT_ENDING_HOUR,
							inventoryHour: {
								hour: DEFAULT_INVENTORY_HOUR,
								minute: DEFAULT_INVENTORY_MINUTE
							},
							multiplicationIndex: DEFAULT_MULTIPLICATION_INDEX
						},
						{
							day: "Thursday",
							startingHour: DEFAULT_STARTING_HOUR,
							endingHour: DEFAULT_ENDING_HOUR,
							inventoryHour: {
								hour: DEFAULT_INVENTORY_HOUR,
								minute: DEFAULT_INVENTORY_MINUTE
							},
							multiplicationIndex: DEFAULT_MULTIPLICATION_INDEX
						},
						{
							day: "Friday",
							startingHour: DEFAULT_STARTING_HOUR,
							endingHour: DEFAULT_ENDING_HOUR,
							inventoryHour: {
								hour: DEFAULT_INVENTORY_HOUR,
								minute: DEFAULT_INVENTORY_MINUTE
							},
							multiplicationIndex: DEFAULT_MULTIPLICATION_INDEX
						},
						{
							day: "Saturday",
							startingHour: DEFAULT_STARTING_HOUR,
							endingHour: DEFAULT_ENDING_HOUR,
							inventoryHour: {
								hour: DEFAULT_INVENTORY_HOUR,
								minute: DEFAULT_INVENTORY_MINUTE
							},
							multiplicationIndex: DEFAULT_MULTIPLICATION_INDEX
						},
						{
							day: "Sunday",
							startingHour: DEFAULT_STARTING_HOUR,
							endingHour: DEFAULT_ENDING_HOUR,
							inventoryHour: {
								hour: DEFAULT_INVENTORY_HOUR,
								minute: DEFAULT_INVENTORY_MINUTE
							},
							multiplicationIndex: DEFAULT_MULTIPLICATION_INDEX
						},
					];

					return;
				}

				for (let i = 0; i < 7; i++) {
					this.allAvailableEndHours[i] = _.cloneDeep(this.allAvailableHours[0]);
					this.allAvailableEndHours[i] = this.allAvailableEndHours[i].filter((item: number) => item > this.hours[i].startingHour);

					this.allAvailableStartHours[i] = _.cloneDeep(this.allAvailableHours[0]);
					this.allAvailableStartHours[i] = this.allAvailableStartHours[i].filter((item: number) => item < this.hours[i].endingHour);
				}
			},
			deep: true,
		}
	}
});
